import { useState } from 'react'
import { CaretUpDown, MagnifyingGlass } from '@phosphor-icons/react'
import { useCompany } from '@/api'
import { Company, Workspace, WorkspaceMemberRole } from '@/api/core'
import { WorkspaceMemberRoleKeys, WorkspaceMemberRoleLabels, WorkspaceMemberRoleToText } from '@/api/text/workspace'
import { useWorkspace } from '@/api/workspace'
import { FeatureFlag } from '@/containers/FeatureFlag'
import { Button, Input, Listbox, Text } from '@/ui'
import { AddMemberModal } from './AddMemberModal'
import { Invitations } from './Invitations'
import { MemberCard } from './MemberCard'

export function Members() {
  const { data: company, status: companyStatus } = useCompany()
  const { data: workspace, status: workspaceStatus } = useWorkspace()
  const [openAddMember, setOpenAddMember] = useState(false)

  return (
    <>
      <AddMemberModal setIsOpen={setOpenAddMember} isOpen={openAddMember} />
      <section className="space-y-4">
        <div>
          <span className="flex items-center justify-between pb-2">
            <Text variant="title">Organization members</Text>
            {company && !company.data.permissions.edit.deny && (
              <Button variant="text" onClick={() => setOpenAddMember(true)}>
                Add members
              </Button>
            )}
          </span>
          <Text>Users who have access to this organization</Text>
        </div>

        <div>
          {(companyStatus === 'pending' || workspaceStatus === 'pending') && <div className="skeleton h-64 w-full" />}
          {companyStatus === 'success' && workspaceStatus === 'success' && (
            <MemberSettings company={company.data} workspace={workspace.data} />
          )}
          <FeatureFlag className="mb-8">
            <Invitations />
          </FeatureFlag>
        </div>
      </section>
    </>
  )
}

function MemberSettings({ company, workspace }: { company: Company; workspace: Workspace }) {
  const canEdit = company.permissions.edit.deny === false
  const [search, setSearch] = useState('')
  const [roles, setRoles] = useState<WorkspaceMemberRole[]>([])

  const buttonLabel =
    roles.length === WorkspaceMemberRoleKeys.length || roles.length === 0
      ? 'All'
      : roles.map((v) => WorkspaceMemberRoleToText[v]).join(',')

  return (
    <>
      <div className="flex w-full gap-2">
        <Input
          icon={MagnifyingGlass}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          containerClassName="grow m-0"
        />

        <Listbox multiple value={roles} onChange={setRoles}>
          <div className="relative">
            <Listbox.Button className="cursor-default">
              {buttonLabel}
              <CaretUpDown className="ml-1 size-5 text-gray-400" aria-hidden="true" />
            </Listbox.Button>
            <Listbox.Options className="right-0">
              {WorkspaceMemberRoleLabels.map((p) => (
                <Listbox.Option key={p.value} value={p.value}>
                  {p.label}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </div>
        </Listbox>
      </div>
      {workspace.members
        .filter((v) => roles.length === 0 || roles.includes(v.role))
        .filter((v) =>
          search !== ''
            ? v.first_name.indexOf(search) > -1 || v.last_name.indexOf(search) > -1 || v.email.indexOf(search) > -1
            : v,
        )
        .map((v) => (
          <MemberCard key={v.member_id} member={v} canEdit={canEdit} />
        ))}
    </>
  )
}
