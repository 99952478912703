import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  ApiError,
  DefaultService,
  PatchWorkspaceMember,
  PatchWorkspaceMemberDelete,
  PostWorkspaceInvitationAccept,
  PostWorkspaceMember,
  Workspace,
  WorkspaceViews,
} from '@/api/core'

export const queryKey = {
  base: () => ['workspace'] as const,
  invitations: () => ['workspace', 'invitations'] as const,
  views: () => ['workspace', 'views'] as const,
}

export function useWorkspace() {
  return useQuery<{ data: Workspace }, ApiError>({
    queryKey: queryKey.base(),
    queryFn: () => DefaultService.getWorkspace(),
  })
}

export function useWorkspaceInvitations() {
  return useQuery({
    queryKey: queryKey.invitations(),
    queryFn: () => DefaultService.getWorkspaceInvitations(),
  })
}

export function useWorkspaceInvitationAccept(invitationId: string) {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.invitations() })
    },
    mutationFn: (body: PostWorkspaceInvitationAccept) => {
      return DefaultService.postWorkspaceInvitationsAccept(invitationId, body)
    },
  })
}
export function useWorkspaceViews({ enabled = false }) {
  return useQuery<{ data: WorkspaceViews }, ApiError>({
    queryKey: queryKey.views(),
    queryFn: () => DefaultService.getWorkspaceViews(),
    enabled: enabled,
  })
}

export function useWorkspaceMemberUpdate() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.base() })
    },
    mutationFn: (data: PatchWorkspaceMember & { memberId: string }) => {
      const { memberId, ...body } = data
      return DefaultService.patchWorkspaceMembers(memberId, body)
    },
  })
}

export function useWorkspaceMemberDelete() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.base() })
    },
    mutationFn: (body: PatchWorkspaceMemberDelete & { memberId: string }) => {
      const { memberId, ...rest } = body
      return DefaultService.patchWorkspaceMembersDelete(memberId, rest)
    },
  })
}

export function useWorkspaceMembersCreate() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.base() })
    },
    mutationFn: (data: PostWorkspaceMember) => {
      return DefaultService.postWorkspaceMembers(data)
    },
  })
}

export function useWorkspaceMemberReminder() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.base() })
    },
    mutationFn: (memberId: string) => {
      return DefaultService.postWorkspaceMembersReminder(memberId)
    },
  })
}
