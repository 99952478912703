import { WorkspaceViewId } from '@/api/core'
import { useUserContext } from '@/providers/User/context'
import { FeatureFlag } from '../FeatureFlag'

type PermissionGuardProps = {
  permission: WorkspaceViewId
}

export function PermissionGuard(props: React.PropsWithChildren<PermissionGuardProps>) {
  const { children, permission } = props
  const { permissions } = useUserContext()

  const component = permissions.find((p) => p.view_id === permission)
  // show behind feature flag always, to make it easier for FF users
  // TODO: think about this approach, null should be returned if component is not allowed
  if (!component || !component.allow) return <FeatureFlag>{children}</FeatureFlag>

  return <>{children}</>
}

export function useUserPermissions() {
  const { permissions } = useUserContext()

  const viewAllowed = (workspaceViewId: WorkspaceViewId) =>
    permissions.findIndex((permission) => permission.view_id === workspaceViewId && permission.allow) > -1

  return {
    loading: !permissions.length,
    showWorkspace: viewAllowed(WorkspaceViewId.WORKSPACES),
    showPasswordVault: viewAllowed(WorkspaceViewId.PASSWORD_VAULT),
    showSettingsSalesforceIntegration: viewAllowed(WorkspaceViewId.SETTINGS_SALESFORCE_INTEGRATION),
    showSequenceV2: viewAllowed(WorkspaceViewId.SEQUENCES_V2),
    showSequenceSchedule: viewAllowed(WorkspaceViewId.SEQUENCES_SCHEDULE),
    showSequenceLabels: viewAllowed(WorkspaceViewId.SEQUENCES_LABELS),
    showMeetings: viewAllowed(WorkspaceViewId.MEETINGS),
    showDialer: viewAllowed(WorkspaceViewId.DIALER),
    showImportV2: viewAllowed(WorkspaceViewId.IMPORT_V2),
    showRecommendations: viewAllowed(WorkspaceViewId.RECOMMENDATIONS),
    showWritingAssistance: viewAllowed(WorkspaceViewId.WRITING_ASSISTANCE),
    showSmtpReplyToHeader: viewAllowed(WorkspaceViewId.SMTP_REPLY_TO_HEADER),
  }
}
