import { useEffect } from 'react'
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom'
import { storePathOpen } from '@/api'
import { ChameleonBanner } from '@/containers/Chameleon'
import { Cmdk } from '@/containers/Cmdk'
import { useUserPermissions } from '@/containers/ComponentDisplay'
import { useFlags } from '@/containers/FeatureFlag/useFlags'
import { BoundedPage } from '@/containers/Layout/BoundedPage'
import { MainLayout } from '@/containers/Layout/MainLayout'
import { Admin, EmailStats } from '@/pages/Admin'
import { ContactListView } from '@/pages/Contacts/ContactListView'
import { ContactsView } from '@/pages/Contacts/ContactsView'
import { ImportV2 } from '@/pages/Contacts/Import/ImportV2'
import { Details as EmailDetails } from '@/pages/Emails/Details'
import { Edit as EmailEdit } from '@/pages/Emails/Edit'
import { Inbox } from '@/pages/Inbox'
import { Calls } from '@/pages/Inbox/Calls'
import { DoneCall } from '@/pages/Inbox/Calls/DoneCall'
import { SingleCall as SingleCallInbox } from '@/pages/Inbox/Calls/SingleCall'
import { Done } from '@/pages/Inbox/Done'
import { Emails } from '@/pages/Inbox/Emails'
import { SingleEmail as SingleEmailInbox } from '@/pages/Inbox/Emails/Single'
import { DoneEmail } from '@/pages/Inbox/Emails/Single/DoneEmail'
import { LinkedinMessages as LinkedinMessagesInbox } from '@/pages/Inbox/LinkedinMessages'
import { SingleLinkedinMessage as SingleLinkedinMessageInbox } from '@/pages/Inbox/LinkedinMessages/Single'
import { DoneLinkedinMessage } from '@/pages/Inbox/LinkedinMessages/Single/DoneLinkedinMessage'
import { Recommendations } from '@/pages/Inbox/Recommendations'
import { DoneRecommendation } from '@/pages/Inbox/Recommendations/DoneRecommendation'
import { SingleRecommendation } from '@/pages/Inbox/Recommendations/SingleRecommendation'
import { Replies } from '@/pages/Inbox/Replies'
import { DoneReply } from '@/pages/Inbox/Replies/DoneReply'
import { SingleReply } from '@/pages/Inbox/Replies/SingleReply'
import { EditMeetingType } from '@/pages/Meetings/EditMeetingType'
import { Meetings } from '@/pages/Meetings/Meetings'
import { NewMeetingType } from '@/pages/Meetings/NewMeetingType'
import { Index } from '@/pages/meetingsportal'
import { Onboarding } from '@/pages/Onboarding'
import { SequencesList } from '@/pages/Sequences/Table'
import { SequenceView } from '@/pages/Sequences/View'
import { SettingsUpdates } from '@/pages/SettingsUpdates'
import { Availability } from '@/pages/SettingsUpdates/Sections/Availability'
import { Company } from '@/pages/SettingsUpdates/Sections/Company'
import { Integrations } from '@/pages/SettingsUpdates/Sections/Integrations'
import { HubspotSettings } from '@/pages/SettingsUpdates/Sections/Integrations/Connections/Hubspot'
import {
  SalesforceContactsSynchronizationDebug,
  SalesforceFieldMapping,
  SalesforceIntegrationDetails,
  SalesforceOnboarding,
  SalesforceStageMapping,
  SalesforceUserMapping,
} from '@/pages/SettingsUpdates/Sections/Integrations/Connections/Salesforce'
import { LinkedAccounts } from '@/pages/SettingsUpdates/Sections/LinkedAccounts'
import { GoogleMail } from '@/pages/SettingsUpdates/Sections/LinkedAccounts/GoogleMail'
import { Members } from '@/pages/SettingsUpdates/Sections/Members'
import { NoSendDates } from '@/pages/SettingsUpdates/Sections/NoSendDates'
import { PhoneNumbers } from '@/pages/SettingsUpdates/Sections/PhoneNumbers'
import { Profile } from '@/pages/SettingsUpdates/Sections/Profile'
import { ReplyTo } from '@/pages/SettingsUpdates/Sections/ReplyTo'
import { Security } from '@/pages/SettingsUpdates/Sections/Security'
import { Senders } from '@/pages/SettingsUpdates/Sections/Senders'
import { Signatures } from '@/pages/SettingsUpdates/Sections/Signatures'
import { Support } from '@/pages/Support'
import { Unsubscribe } from '@/pages/Unsubscribe'
import { Vault } from '@/pages/Vault'
import { Verification } from '@/pages/Verification'
import { ZoomDocumentation } from '@/pages/ZoomDocumentation'

export function ProtectedRoutes() {
  const location = useLocation()
  const { loading, showPasswordVault, showSmtpReplyToHeader, showRecommendations } = useUserPermissions()
  const { showFeatureFlag, showFeatureFlagRaw } = useFlags()

  useEffect(() => {
    storePathOpen(location.pathname)
  }, [location])

  return (
    <>
      <Routes>
        <Route
          element={
            <>
              {showFeatureFlag && <ChameleonBanner />}
              {showFeatureFlag && <Cmdk />}
              <Outlet />
            </>
          }
        >
          {/* Not bounded pages */}
          <Route path="sequences/create">
            <Route path=":sequenceId" element={<SequenceView />} />
          </Route>

          <Route path="sequences/">
            <Route path=":sequenceId" element={<SequenceView />} />
          </Route>

          <Route path="import" element={<ImportV2 />} />

          <Route
            path="settings"
            element={
              <MainLayout>
                <SettingsUpdates>
                  <Outlet />
                </SettingsUpdates>
              </MainLayout>
            }
          >
            <Route index element={<Navigate to="/settings/profile" />} />
            <Route path="profile" element={<Profile />} />
            <Route path="signatures" element={<Signatures />} />
            <Route path="security" element={<Security />} />
            <Route path="availability" element={<Availability />} />
            <Route path="linked-accounts">
              <Route index element={<LinkedAccounts />} />
              <Route path="google-mail" element={<GoogleMail />} />
            </Route>
            <Route path="members" element={<Members />} />
            <Route path="senders" element={<Senders />} />
            {showSmtpReplyToHeader && <Route path="replyto" element={<ReplyTo />} />}
            <Route path="phone-numbers" element={<PhoneNumbers />} />
            <Route path="company" element={<Company />} />
            <Route path="no-send-dates" element={<NoSendDates />} />
            <Route path="integrations">
              <Route index element={<Integrations />} />
              <Route path="salesforce">
                <Route index element={<SalesforceIntegrationDetails />} />
                <Route path="onboarding" element={<SalesforceOnboarding />} />
                <Route path="user-mapping" element={<SalesforceUserMapping />} />
                <Route path="field-mapping" element={<SalesforceFieldMapping />} />
                <Route path="stage-mapping" element={<SalesforceStageMapping />} />
                <Route path="contacts-synchronization-debug" element={<SalesforceContactsSynchronizationDebug />} />
              </Route>
              <Route path="hubspot" element={<HubspotSettings />} />
            </Route>
          </Route>

          {/* Inbox routes */}
          <Route
            element={
              <MainLayout backgroundColor="bg-extra-light">
                <BoundedPage>
                  <Inbox>
                    <Outlet />
                  </Inbox>
                </BoundedPage>
              </MainLayout>
            }
          >
            <Route
              path="inbox"
              element={
                showFeatureFlag ? (
                  showRecommendations ? (
                    <Navigate to="/inbox/recommendations" />
                  ) : (
                    <Navigate to="/inbox/replies" />
                  )
                ) : (
                  <Navigate to="/inbox/emails" />
                )
              }
            />
            <Route path="inbox/emails" element={<Emails />} />
            {showRecommendations && <Route path="inbox/recommendations" element={<Recommendations />} />}
            <Route path="inbox/replies" element={<Replies />} />
            <Route path="/inbox/linkedin-messages" element={<LinkedinMessagesInbox />} />
            {/* TODO: might need to add provider to this? */}
            <Route path="/inbox/calls" element={<Calls />} />
            <Route path="/inbox/done" element={<Done />} />
          </Route>

          {/* Inbox individual steps pages do not use the main layout */}
          <Route path="inbox/emails">
            <Route index path=":emailId" element={<SingleEmailInbox />} />
            <Route path=":emailId/done" element={<DoneEmail />} />
          </Route>

          {showRecommendations && (
            <Route path="inbox/recommendations">
              <Route index path=":recommendationId" element={<SingleRecommendation />} />
              <Route path=":recommendationId/done" element={<DoneRecommendation />} />
            </Route>
          )}

          <Route path="inbox/replies">
            <Route index path=":replyId" element={<SingleReply />} />
            <Route path=":replyId/done" element={<DoneReply />} />
          </Route>

          <Route path="inbox/linkedin-messages">
            <Route index path=":eventId" element={<SingleLinkedinMessageInbox />} />
            <Route path=":eventId/done" element={<DoneLinkedinMessage />} />
          </Route>

          <Route path="inbox/calls">
            <Route index path=":callId" element={<SingleCallInbox />} />
            <Route path=":callId/done" element={<DoneCall />} />
          </Route>

          {/* End inbox routes */}

          <Route
            element={
              <MainLayout>
                <BoundedPage>
                  <Outlet />
                </BoundedPage>
              </MainLayout>
            }
          >
            <Route path="/" element={<Navigate to="/sequences" />} />

            <Route path="emails/:senderEmailId" element={<EmailDetails />} />
            <Route path="emails/:senderEmailId/edit" element={<EmailEdit />} />

            <Route path="contacts">
              <Route index element={<ContactsView />} />
              <Route path=":contactId" element={<ContactsView />} />
            </Route>
            <Route path="contacts/lists">
              <Route index element={<ContactsView />} />
              <Route path=":contactListId" element={<ContactListView />} />
            </Route>
            <Route path="contacts/accounts">
              <Route index element={<ContactsView />} />
              <Route path=":accountId" element={<ContactsView />} />
            </Route>

            {!showFeatureFlag && (
              <Route path="campaigns">
                {/* Used to route current campaigns to sequences */}
                <Route path=":sequenceId" element={<Navigate to={`/sequences/${location.pathname.split('/')[2]}`} />} />
                <Route index element={<Navigate to="/sequences" />} />
              </Route>
            )}

            <Route path="sequences">
              <Route index element={<SequencesList />} />
            </Route>

            {showFeatureFlagRaw && (
              <Route path="superadmin">
                <Route index element={<Admin />} />
              </Route>
            )}

            {showFeatureFlagRaw && (
              <Route path="email_stats">
                <Route index element={<EmailStats />} />
              </Route>
            )}
            <Route path="/verification" element={<Verification isPublic={false} />} index />
            <Route path="/meetings" element={<Meetings />} />
            <Route path="/meetings/:meeting_type_id/edit" element={<EditMeetingType />} />
            <Route path="/meetings/new" element={<NewMeetingType />} />

            <Route path="/onboarding" element={<Onboarding />} />
            {showPasswordVault && <Route path="/vault" element={<Vault />} />}
          </Route>
          <Route path="/demo" element={<Navigate to="/book/rift/rift-demo-fil" replace />} />

          <Route path="/support" element={<Support />} />
          <Route path="/documents/zoom" element={<ZoomDocumentation />} />
          <Route path="/book/:company/:meeting_type" element={<Index />} />
          <Route path="/unsubscribe/:token" element={<Unsubscribe />} />
          {!loading && <Route path="*" element={<Navigate to="/" />} />}
        </Route>
      </Routes>
    </>
  )
}
