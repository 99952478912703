import { useEffect, useState } from 'react'
import { Question } from '@phosphor-icons/react'
import { Button } from '@/ui/Button/v2'

export function HelpButton() {
  const [showPylon, setShowPylon] = useState(false)

  useEffect(() => {
    if (showPylon) {
      window.Pylon('show')
    } else {
      window.Pylon('hide')
    }
  }, [showPylon])
  return (
    <Button variant="basic" onClick={() => setShowPylon((prev) => !prev)} className="flex gap-1">
      Help <Question className="h-5 w-5 text-medium" />
    </Button>
  )
}
