import { useRef } from 'react'
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { queryKey as chameleonQueryKey } from '@/api/chameleon'
import { queryKey as contactQueryKey, queryKey as contactsQueryKey } from '@/api/contacts'
import {
  ApiError,
  Campaign,
  CampaignActivity,
  CampaignParticipantsAttach,
  CampaignParticipantsDetach,
  CampaignSortField,
  CancelablePromise,
  DefaultService,
  EventType,
  PatchCampaign,
  PatchCampaignAddMissingSenders,
  PatchCampaignAttachContactList,
  PatchCampaignContactReassignOwnership,
  PatchCampaignContactRedistribute,
  PatchCampaignContactsAbandon,
  PatchCampaignContactsReschedule,
  PatchCampaignContactsUnsubscribe,
  PatchCampaignLabels,
  PatchCampaignsArchive,
  PatchCampaignShareeShare,
  PatchCampaignsStop,
  PostCampaignContactsDelete,
} from '@/api/core'
import { queryKey as manualStepsQueryKey } from '@/api/manual_step'

export const queryKey = {
  base: () => ['campaigns'] as const,
  metrics: (startDate: string | undefined, endDate: string | undefined) =>
    ['campaigns', 'metrics', [startDate, endDate]] as const,
  list: () => ['campaigns', 'list'] as const,
  listSPF: (
    page: number,
    sort: CampaignSortField,
    q: string,
    archived: boolean,
    filter_by_labels: string,
    access: string,
    includeAnalytics: boolean,
  ) => ['campaigns', 'list', page, sort, q, archived, filter_by_labels, access, includeAnalytics] as const,
  get: (id: string) => ['campaigns', id] as const,
  senders: (id: string) => ['campaigns', id, 'senders'] as const,
  analytics: (id: string) => ['campaigns', id, 'analytics'] as const,
  analyticsTimeframe: (id: string, createdAtLTE: number, createdAtGTE: number) =>
    ['campaigns', id, 'analytics', { createdAtLTE, createdAtGTE }] as const,
  activity: (id: string, before: number, limit: number, q: string, statues: Array<EventType>) =>
    ['campaigns', id, 'activity', { before, limit, q, statues }] as const,
  contacts: (id: string) => ['campaigns', id, 'contacts'] as const,
  contactsCount: (id: string) => ['campaigns', id, 'contacts', 'count'] as const,
  contactsSample: (id: string) => ['campaigns', id, 'contacts', 'sample'] as const,
  estimatedEndDate: (id: string) => ['campaigns', id, 'estimatedEndDate'] as const,
  dailyCapacity: (id: string) => ['campaigns', id, 'dailyCapacity'] as const,
  participants: (id: string) => ['campaigns', id, 'participants'] as const,
  companyProducts: (id: string) => ['campaigns', id, 'products'] as const,
  labels: (id: string) => ['campaigns', id, 'labels'] as const,
  schedule: (id: string) => ['campaigns', id, 'schedule'] as const,
  contactsCustomFieldNames: (id: string) => ['campaigns', id, 'contacts', 'customFieldNames'] as const,
  sharee: (id: string) => ['campaigns', id, 'sharee'] as const,
  recommendation: (id: string, promptEngine: 'llm' | 'wordware') =>
    ['campaigns', 'recommendations', id, promptEngine] as const,
}

export function useCampaignCreate() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.list() }),
    mutationFn: () => DefaultService.postCampaigns(),
  })
}

export function useCampaigns(
  page: number,
  sort: CampaignSortField,
  q: string,
  archived: boolean,
  filter_by_labels: string,
  access: 'ALL' | 'PRIVATE' | 'SHARED' = 'ALL',
  includeAnalytics: boolean = false,
) {
  return useQuery({
    queryKey: queryKey.listSPF(page, sort, q, archived, filter_by_labels, access, includeAnalytics),
    queryFn: () => DefaultService.getCampaigns(q, page, sort, archived, filter_by_labels, access, includeAnalytics),
    placeholderData: keepPreviousData,
  })
}

export function useCampaign(campaignId: string) {
  const queryClient = useQueryClient()
  return useQuery({
    queryKey: queryKey.get(campaignId),
    queryFn: async () => {
      const resp = await DefaultService.getCampaigns1(campaignId)
      // on every campaign fetch, invalidate the chameleon query
      // this is a special case because only for this endpoint the backend
      // can auto-chameleon the user, this is why we want to invalidate it here.
      queryClient.invalidateQueries({ queryKey: chameleonQueryKey.base })
      return resp
    },
  })
}

export function useCampaignDelete() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.list() }),
    mutationFn: (campaignId: string) => DefaultService.deleteCampaigns(campaignId),
  })
}

export function useCampaignClone() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.list() }),
    mutationFn: (campaignId: string) => DefaultService.postCampaignsClone(campaignId),
  })
}

export function useCampaignArchive() {
  const queryClient = useQueryClient()
  return useMutation({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSuccess: (data, campaignId, context) => {
      queryClient.invalidateQueries({ queryKey: queryKey.get(campaignId) })
      queryClient.invalidateQueries({ queryKey: queryKey.list() })
    },
    mutationFn: (campaignId: string) => DefaultService.patchCampaignArchive(campaignId),
  })
}

export function useCampaignsArchive() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.list() })
    },
    mutationFn: (body: PatchCampaignsArchive) => DefaultService.patchCampaignsArchive(body),
  })
}

export function useCampaignUnarchive() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: (_data, campaignId) => {
      queryClient.invalidateQueries({ queryKey: queryKey.get(campaignId) })
      queryClient.invalidateQueries({ queryKey: queryKey.list() })
    },
    mutationFn: (campaignId: string) => DefaultService.patchCampaignsUnarchive(campaignId),
  })
}

export function useCampaignAnalytics(
  campaignId: string,
  createdAtGTE: number,
  createdAtLTE: number | undefined | null,
) {
  return useQuery({
    queryKey: queryKey.analyticsTimeframe(campaignId, createdAtLTE as number, createdAtGTE),
    queryFn: () => DefaultService.getCampaignsAnalytics(campaignId, createdAtLTE, createdAtGTE),
  })
}

export function useCampaignActivity(
  campaignId: string,
  q: string,
  before: number,
  limit: number,
  statuses: Array<EventType>,
) {
  return useQuery<
    {
      data: CampaignActivity
      metadata: {
        more: boolean
      }
    },
    ApiError
  >({
    placeholderData: keepPreviousData,
    queryKey: queryKey.activity(campaignId, before, limit, q, statuses),
    queryFn: () => DefaultService.getEventsCampaigns(campaignId, q, limit, before, statuses),
  })
}

export function useCampaignUpdate(campaignId: string) {
  const queryClient = useQueryClient()
  const inflightUpdateRequest = useRef<CancelablePromise<{
    data: Campaign
  }> | null>(null)
  return useMutation({
    onSuccess: (data) => {
      queryClient.setQueryData(queryKey.get(campaignId), data)
      queryClient.invalidateQueries({ queryKey: queryKey.list() })
      queryClient.invalidateQueries({ queryKey: contactQueryKey.campaign(campaignId) })
      queryClient.invalidateQueries({ queryKey: queryKey.estimatedEndDate(campaignId) })
      queryClient.invalidateQueries({ queryKey: queryKey.dailyCapacity(campaignId) })
      queryClient.invalidateQueries({ queryKey: queryKey.participants(campaignId) })
    },
    mutationFn: (body: PatchCampaign) => {
      if (inflightUpdateRequest.current) {
        inflightUpdateRequest.current.cancel()
      }
      const request = DefaultService.patchCampaigns(campaignId, body)
      inflightUpdateRequest.current = request
      return request
    },
  })
}

export function useCampaignContactsSample(campaignId: string) {
  return useQuery({
    queryKey: queryKey.contactsSample(campaignId),
    queryFn: () => DefaultService.getCampaignsContactsSample(campaignId),
  })
}

export function useCampaignContactsDelete() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: (_, vars) => {
      queryClient.invalidateQueries({ queryKey: queryKey.contacts(vars.campaignId) })
      queryClient.invalidateQueries({ queryKey: contactQueryKey.campaign(vars.campaignId) })
    },
    mutationFn: (body: PostCampaignContactsDelete & { campaignId: string }) => {
      const { campaignId, ...requestBody } = body
      return DefaultService.postCampaignsContactsDelete(campaignId, requestBody)
    },
  })
}

export function useCampaignContactsAbandon(campaignId: string) {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.contacts(campaignId) }),
    mutationFn: (body: PatchCampaignContactsAbandon) => DefaultService.patchCampaignsContactsAbandon(campaignId, body),
  })
}

export function useCampaignContactsReschedule(campaignId: string) {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.contacts(campaignId) }),
    mutationFn: (body: PatchCampaignContactsReschedule) =>
      DefaultService.patchCampaignsContactsReschedule(campaignId, body),
  })
}

export function useCampaignContactUnsubscribe(campaignId: string) {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.contacts(campaignId) }),
    mutationFn: (contactId: string) => DefaultService.patchCampaignsContactUnsubscribe(campaignId, contactId),
  })
}

export function useCampaignContactsUnsubscribe(campaignId: string) {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.contacts(campaignId) }),
    mutationFn: (body: PatchCampaignContactsUnsubscribe) =>
      DefaultService.patchCampaignsContactsUnsubscribe(campaignId, body),
  })
}

export function useCampaignLaunch() {
  const queryClient = useQueryClient()
  return useMutation({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: queryKey.list() })
      queryClient.invalidateQueries({ queryKey: queryKey.base() })
      // give some time to the backend to update the campaign status and then revalidate the list
      // this is done to update the campaign running status in the list
      setTimeout(() => queryClient.invalidateQueries({ queryKey: queryKey.list() }), 5000)
      setTimeout(() => queryClient.invalidateQueries({ queryKey: queryKey.list() }), 10000)

      queryClient.invalidateQueries({ queryKey: queryKey.get(variables.campaignId) })
      // same as above, give some time to the backend to update the campaign status and then
      // revalidate the campaign analytics page
      setTimeout(() => queryClient.invalidateQueries({ queryKey: queryKey.analytics(variables.campaignId) }), 5000)

      // make sure notification modal about numbers of emails to write is
      // updated on every sequence launch, but at the same time give set a
      // time buffer to let the sequence launch
      setTimeout(() => queryClient.invalidateQueries({ queryKey: manualStepsQueryKey.info() }), 3000)
      setTimeout(() => queryClient.invalidateQueries({ queryKey: manualStepsQueryKey.info() }), 7000)
      setTimeout(() => queryClient.invalidateQueries({ queryKey: manualStepsQueryKey.info() }), 10000)
      setTimeout(() => queryClient.invalidateQueries({ queryKey: manualStepsQueryKey.info() }), 20000)
      setTimeout(() => queryClient.invalidateQueries({ queryKey: manualStepsQueryKey.info() }), 30000)
    },
    mutationFn: ({ campaignId }: { campaignId: string }) => DefaultService.patchCampaignsLaunch(campaignId),
  })
}

export function useCampaignStop() {
  const queryClient = useQueryClient()
  return useMutation({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: queryKey.list() })
      queryClient.invalidateQueries({ queryKey: queryKey.base() })

      // make sure notification modal about numbers of emails to write is
      // updated on every sequence launch, but at the same time give set a
      // time buffer to let the sequence launch
      setTimeout(() => queryClient.invalidateQueries({ queryKey: manualStepsQueryKey.info() }), 3000)
      setTimeout(() => queryClient.invalidateQueries({ queryKey: manualStepsQueryKey.info() }), 7000)
      setTimeout(() => queryClient.invalidateQueries({ queryKey: manualStepsQueryKey.info() }), 10000)
      setTimeout(() => queryClient.invalidateQueries({ queryKey: manualStepsQueryKey.info() }), 20000)
      setTimeout(() => queryClient.invalidateQueries({ queryKey: manualStepsQueryKey.info() }), 30000)

      // give some time to the backend to update the campaign status and then revalidate the list
      // this is done to update the campaign running status in the list
      setTimeout(() => queryClient.invalidateQueries({ queryKey: queryKey.list() }), 5000)
      setTimeout(() => queryClient.invalidateQueries({ queryKey: queryKey.list() }), 15000)

      queryClient.invalidateQueries({ queryKey: queryKey.get(variables.campaignId) })
      // same as above, give some time to the backend to update the campaign status and then
      // revalidate the campaign analytics page
      setTimeout(() => queryClient.invalidateQueries({ queryKey: queryKey.analytics(variables.campaignId) }), 5000)
    },
    mutationFn: ({ campaignId }: { campaignId: string }) => DefaultService.patchCampaignStop(campaignId),
  })
}

export function useCampaignsStop() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.list() })

      // make sure notification modal about numbers of emails to write is
      // updated on every sequence stop, but at the same time give set a
      // time buffer to let the sequence stop
      setTimeout(() => queryClient.invalidateQueries({ queryKey: manualStepsQueryKey.info() }), 5000)

      // give some time to the backend to update the campaign status and then revalidate the list
      // this is done to update the campaign running status in the list
      setTimeout(() => queryClient.invalidateQueries({ queryKey: queryKey.base() }), 5000)
      setTimeout(() => queryClient.invalidateQueries({ queryKey: queryKey.base() }), 10000)
    },
    mutationFn: (body: PatchCampaignsStop) => DefaultService.patchCampaignsStop(body),
  })
}

export function useCampaignsReschedule() {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.list() })
      // give some time to the backend to update the campaign status and then revalidate the list
      // this is done to update the campaign running status in the list
      setTimeout(() => queryClient.invalidateQueries({ queryKey: queryKey.list() }), 5000)
      setTimeout(() => queryClient.invalidateQueries({ queryKey: queryKey.list() }), 10000)
    },
    mutationFn: () => DefaultService.postCampaignsReschedule(),
  })
}

export function useCampaignEstimatedEndDate(campaignId: string) {
  return useQuery({
    queryKey: queryKey.estimatedEndDate(campaignId),
    queryFn: () => DefaultService.getCampaignsEstEndDate(campaignId),
  })
}

export function useCampaignDailyCapacity(campaignId: string) {
  return useQuery({
    queryKey: queryKey.dailyCapacity(campaignId),
    queryFn: () => DefaultService.getCampaignsDailyCapacity(campaignId),
  })
}

export function useCampaignParticipants(campaignId: string) {
  return useQuery({
    queryKey: queryKey.participants(campaignId),
    queryFn: () => DefaultService.getCampaignsParticipants(campaignId),
  })
}

export function useCampaignParticipantAttach(campaignId: string) {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.participants(campaignId) })
      queryClient.invalidateQueries({ queryKey: queryKey.estimatedEndDate(campaignId) })
      queryClient.invalidateQueries({ queryKey: queryKey.dailyCapacity(campaignId) })
      queryClient.invalidateQueries({ queryKey: queryKey.contactsSample(campaignId) })
    },
    mutationFn: (body: CampaignParticipantsAttach) => DefaultService.patchCampaignsParticipantsAttach(campaignId, body),
  })
}

export function useCampaignParticipantDetach(campaignId: string) {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.participants(campaignId) })
      queryClient.invalidateQueries({ queryKey: queryKey.estimatedEndDate(campaignId) })
      queryClient.invalidateQueries({ queryKey: queryKey.dailyCapacity(campaignId) })
      queryClient.invalidateQueries({ queryKey: queryKey.contactsSample(campaignId) })
    },
    mutationFn: (body: CampaignParticipantsDetach) => DefaultService.patchCampaignsParticipantsDetach(campaignId, body),
  })
}

export function useCampaignLabels(campaignId: string) {
  return useQuery({
    queryKey: queryKey.labels(campaignId),
    queryFn: () => DefaultService.getCampaignsLabels(campaignId),
  })
}

export function usePatchCampaignLabels() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ campaignId, body }: { campaignId: string; body: PatchCampaignLabels }) =>
      DefaultService.patchCampaignsLabels(campaignId, body),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: queryKey.labels(variables.campaignId) })
      queryClient.invalidateQueries({ queryKey: queryKey.list() })
    },
  })
}

export function useCampaignCompanyProducts(campaignId: string) {
  return useQuery({
    queryKey: queryKey.companyProducts(campaignId),
    queryFn: () => DefaultService.getCampaignsCompanyProducts(campaignId),
  })
}

export function useCampaignAttachContactList() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (data: PatchCampaignAttachContactList & { campaignId: string }) => {
      const { campaignId, ...body } = data
      return DefaultService.patchCampaignsAttachContactList(campaignId, body)
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: contactsQueryKey.campaign(variables.campaignId) })
      queryClient.invalidateQueries({ queryKey: queryKey.get(variables.campaignId) })
    },
  })
}

export function useCampaignAppendContactList() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (data: PatchCampaignAttachContactList & { campaignId: string }) => {
      const { campaignId, ...body } = data
      return DefaultService.patchCampaignsAttachContactList(campaignId, body)
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: contactsQueryKey.campaign(variables.campaignId) })
      queryClient.invalidateQueries({ queryKey: queryKey.get(variables.campaignId) })
    },
  })
}

export function useCampaignSchedule(campaignId: string) {
  return useQuery({
    queryKey: queryKey.schedule(campaignId),
    queryFn: () => DefaultService.getCampaignsSchedule(campaignId),
  })
}

export function useCampaignsDashboard() {
  return useQuery({
    queryKey: queryKey.base(),
    queryFn: () => DefaultService.getCampaignsDashboard(),
  })
}

export function useCampaignsMetrics(startDate: string | undefined, endDate?: string | undefined) {
  return useQuery({
    queryKey: queryKey.metrics(startDate, endDate),
    placeholderData: keepPreviousData,
    queryFn: () => DefaultService.getCampaignsMetrics(startDate, endDate),
  })
}

export function useCampaignContactsCustomFieldNames(campaignId: string) {
  return useQuery({
    queryKey: queryKey.contactsCustomFieldNames(campaignId),
    queryFn: () => DefaultService.getCampaignsContactsCustomFieldNames(campaignId),
  })
}

export function useCampaignAddMissingContactsSenders() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (body: PatchCampaignAddMissingSenders & { campaignId: string }) => {
      const { campaignId, ...requestBody } = body
      return DefaultService.patchCampaignsAddMissingSenders(campaignId, requestBody)
    },
    onSuccess: (_, vars) => {
      queryClient.invalidateQueries({ queryKey: queryKey.get(vars.campaignId) })
      queryClient.invalidateQueries({ queryKey: contactsQueryKey.campaign(vars.campaignId) })
    },
  })
}

export function useCampaignContactsReassignOwnership() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (body: PatchCampaignContactReassignOwnership & { campaignId: string }) => {
      const { campaignId, ...requestBody } = body
      return DefaultService.patchCampaignsContactsReassignOwnership(campaignId, requestBody)
    },
    onSuccess: (_, vars) => {
      queryClient.invalidateQueries({ queryKey: queryKey.get(vars.campaignId) })
      queryClient.invalidateQueries({ queryKey: contactsQueryKey.campaign(vars.campaignId) })
    },
  })
}

export function useCampaignContactsRedistribute() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (body: PatchCampaignContactRedistribute & { campaignId: string }) => {
      const { campaignId, ...requestBody } = body
      return DefaultService.patchCampaignsContactsRedistribute(campaignId, requestBody)
    },
    onSuccess: (_, vars) => {
      queryClient.invalidateQueries({ queryKey: queryKey.get(vars.campaignId) })
      queryClient.invalidateQueries({ queryKey: contactsQueryKey.campaign(vars.campaignId) })
    },
  })
}

export function useCampaignSharees(campaignId: string, options?: { enabled?: boolean }) {
  const { enabled } = options || { enabled: true }
  return useQuery({
    enabled,
    queryKey: queryKey.sharee(campaignId),
    queryFn: () => DefaultService.getCampaignsSharees(campaignId),
  })
}

export function useCampaignShareesRevoke(campaignId: string) {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.sharee(campaignId) }),
    mutationFn: (memberId: string) => DefaultService.patchCampaignsShareesRevoke(campaignId, memberId),
  })
}

export function useCampaignShare(campaignId: string) {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.sharee(campaignId) }),
    mutationFn: (body: PatchCampaignShareeShare & { memberId: string }) => {
      const { memberId, ...rest } = body
      return DefaultService.patchCampaignsShareesShare(campaignId, memberId, rest)
    },
  })
}

export function useCampaignStepAppend(campaignId: string) {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.get(campaignId) })
      queryClient.invalidateQueries({ queryKey: queryKey.list() })
      // queryClient.invalidateQueries({ queryKey: queryKey.estimatedEndDate(campaignId) })
      queryClient.invalidateQueries({ queryKey: queryKey.dailyCapacity(campaignId) })
    },
    mutationFn: () => DefaultService.patchCampaignsStepsAppend(campaignId),
  })
}

export function useCampaignStepAddB(campaignId: string) {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.get(campaignId) })
      queryClient.invalidateQueries({ queryKey: queryKey.list() })
      queryClient.invalidateQueries({ queryKey: queryKey.estimatedEndDate(campaignId) })
      queryClient.invalidateQueries({ queryKey: queryKey.dailyCapacity(campaignId) })
    },
    mutationFn: (stepId: string) => DefaultService.patchCampaignsStepsAb(campaignId, stepId),
  })
}

export function useCampaignRecommendation(campaignId: string, promptEngine: 'llm' | 'wordware') {
  return useQuery({
    enabled: false,
    queryKey: queryKey.recommendation(campaignId, promptEngine),
    queryFn: () => DefaultService.getCampaignsRecommendations(campaignId, promptEngine),
  })
}
