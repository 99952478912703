import { useForm } from 'react-hook-form'
import clsx from 'clsx'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { DefaultService, PutGoogleWSCredentials } from '@/api/core'
import { useOnboardingPutGoogleWSCredentials } from '@/api/onboarding'
import { totpSecretFromClipboard } from '@/lib/totp'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { ButtonLoading, Input } from '@/ui'
import { Step } from '@/ui/headless'
import { InputPassword } from '@/ui/Input/v2'

export function GoogleWorkspace() {
  const toast = useToast()
  const schema: yup.ObjectSchema<PutGoogleWSCredentials> = yup.object().shape({
    email: yup.string().email().required('required'),
    password: yup.string().min(1, '').required('required'),
    totpSecret: yup.string().default(''),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<PutGoogleWSCredentials>({
    resolver: yupResolver(schema),
  })

  const putGoogleWSCredentials = useOnboardingPutGoogleWSCredentials()
  // get type PutGoogleWSCredentials from the useForm so it can be used in the onSubmit function
  // onSubmit function takes data of type PutGoogleWSCredentials
  const onSubmit = (data: PutGoogleWSCredentials) => {
    putGoogleWSCredentials.mutate(data, {
      onSuccess: async () => {
        try {
          const resp = await DefaultService.getGoogleOauth('onboarding', data.email)
          window.location.href = resp.data.redirect_url
        } catch (err) {
          toast.createToast({ message: (err as any)?.body?.message ?? 'Something went wrong', error: true })
        }
      },
      onError: (err) => {
        toast.createToast({ message: (err as any)?.body?.message ?? 'Something went wrong', error: true })
      },
    })
  }
  const onToptPaste = async (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault()
    const secret = await totpSecretFromClipboard<HTMLInputElement>(e)
    setValue('totpSecret', secret)
  }

  return (
    <form>
      <h1 className="mb-6 text-lg font-medium">Google workspace credentials</h1>
      <div className="flex w-96 flex-col gap-4">
        <Input registration={register('email')} error={errors.email?.message} type="email" label="Email" />
        <Input
          registration={register('password')}
          error={errors.password?.message}
          autoComplete="google-worspace-password"
          type="password"
          label="Password"
        />
        <InputPassword
          registration={register('totpSecret')}
          autoComplete="google-worspace-totp-secret"
          error={errors.totpSecret}
          label="TOTP Secret"
          onPaste={onToptPaste}
        />
      </div>

      <span className={clsx('text-alert', !putGoogleWSCredentials.isError && 'invisible')}>
        Failed to save {(putGoogleWSCredentials.error as any)?.body?.message}
      </span>

      <div className="mt-8 flex justify-end">
        <Step.Next
          type="submit"
          as={ButtonLoading}
          variant="accent"
          isLoading={putGoogleWSCredentials.isPending}
          onClick={(e) => {
            e.preventDefault()
            handleSubmit(onSubmit)()
          }}
        >
          Next: Authorize google workspace
        </Step.Next>
      </div>
    </form>
  )
}
