import { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Dialog, Transition } from '@headlessui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCampaignContactsCustomFieldNames } from '@/api/campaigns'
import { useSingleContactUploadCampaignId } from '@/api/contacts'
import { PostContact } from '@/api/core'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { Button, Input, OpacityTransition, Text } from '@/ui'

type SingleContactWrapper = { onClose: () => void; campaignId: string }
type SingleContactModal = SingleContactWrapper & { customFields: string[] | undefined }

function AddSingleContactModal({ onClose, campaignId, customFields }: SingleContactModal) {
  const displayCustomFields = customFields && customFields?.length > 0
  const [showCustomFields, setShoCustomFields] = useState(false)
  const saveSingleContact = useSingleContactUploadCampaignId()
  const toast = useToast()

  // TODO: add phone validation from https://dev.to/rksainath/empty-phone-number-field-validation-using-yup-phone-34h5
  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Email is invalid'),
    last_name: Yup.string().required('Last name is required'),
    first_name: Yup.string().required('First name is required'),
    company: Yup.string().default(''),
    phone: Yup.string().default(''),
    title: Yup.string().default(''),
    country: Yup.string().default(''),
    state: Yup.string().default(''),
    city: Yup.string().default(''),
    owner_email: Yup.string().default(''),
    /* Linkedin regex:
    (?: ... )? is a non-capturing group with a ? quantifier, making the entire LinkedIn profile URL part optional.
    (?:https?:\/\/)? makes the http:// or https:// part optional.
    (?:www\.)? makes the www. part optional.
    (?:in|company) matches either "in" or "company" part of the LinkedIn URL.
    [^\s\/]+ matches one or more characters that are not whitespace or "/" in the username part. 
    \/? makes the "/" at the end of the URL optional.*/
    linkedin: Yup.string()
      .trim()
      .matches(/^(?:(?:https?:\/\/)?(?:www\.)?linkedin\.com\/(?:in|company)\/[^\s/]+\/?)?$/, 'Invalid Linkedin URL')
      .default(''),
    timezone: Yup.string().nullable().default(null),
    custom_fields: Yup.object().shape({}),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PostContact>({ resolver: yupResolver(validationSchema) })

  function save(data: PostContact) {
    saveSingleContact.mutate(
      { contact: data, campaignId: campaignId },
      {
        onSuccess: () => {
          onClose()
          toast.createToast({ message: 'Contact added' })
        },
        onError: (err) => {
          onClose()
          toast.createToast({ message: (err as any)?.body?.message || 'Could not add single contact', error: true })
        },
      },
    )
  }

  return (
    <Transition appear show={true} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <OpacityTransition show={true}>
          <div className="fixed inset-0 bg-extra-light bg-opacity-60" />
        </OpacityTransition>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <OpacityTransition show={true}>
              <Dialog.Panel className=" w-full max-w-sm transform overflow-hidden rounded-2xl border bg-white p-6 text-left align-middle shadow-rift transition-all">
                <Dialog.Title as={Fragment}>
                  <Text variant="subtitle" className="pb-2">
                    New contact
                  </Text>
                </Dialog.Title>
                <form className="flex flex-col gap-2" onSubmit={handleSubmit(save)}>
                  <Input
                    placeholder="First name"
                    registration={register('first_name')}
                    required
                    error={errors['first_name']}
                  />
                  <Input
                    placeholder="Last name"
                    registration={register('last_name')}
                    required
                    error={errors['last_name']}
                  />
                  <Input
                    placeholder="Email"
                    type="email"
                    registration={register('email')}
                    required
                    error={errors['email']}
                  />
                  <Input placeholder="Company" registration={register('company')} error={errors['company']?.message} />
                  <Input placeholder="Phone" registration={register('phone')} error={errors['phone']?.message} />
                  <Input placeholder="Title" registration={register('title')} error={errors['title']?.message} />
                  <Input
                    placeholder="Linkedin"
                    registration={register('linkedin')}
                    error={errors['linkedin']?.message}
                  />
                  {displayCustomFields && !showCustomFields && (
                    <Button variant="text" className="w-fit p-0" onClick={() => setShoCustomFields(true)}>
                      Show custom fields
                    </Button>
                  )}
                  {displayCustomFields &&
                    showCustomFields &&
                    customFields.map((name) => (
                      <Input key={name} placeholder={name} registration={register(`custom_fields.${name}`)} />
                    ))}
                  <Text variant="subtext" className="py-2 text-medium">
                    New contacts will be added to sequence and saved to your contacts
                  </Text>
                  <div className="flex gap-6">
                    <Button type="button" variant="text" onClick={onClose} className="ml-auto p-0">
                      Cancel
                    </Button>
                    <Button type="submit" variant="text" className="p-0">
                      Add
                    </Button>
                  </div>
                </form>
              </Dialog.Panel>
            </OpacityTransition>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

function withCustomFields(props: SingleContactWrapper) {
  const { data } = useCampaignContactsCustomFieldNames(props.campaignId)

  return <AddSingleContactModal {...props} customFields={data?.data.names} />
}

export const AddSingleContact = withCustomFields
