import { Plus } from '@phosphor-icons/react'
import { DefaultService } from '@/api/core'
import { useFlags } from '@/containers/FeatureFlag/useFlags'
import { useDisclosureV2 } from '@/hooks/useDisclosure'
import { useToast } from '@/providers/Toasts/ToastsProvider'
import { DropdownMenu } from '@/ui'
import { ConnectEmailDialog } from './ConnectEmailDialog'
import { GroupFormDialog } from './GroupFormDialog'

export function SendersDropdownMenu() {
  const toast = useToast()
  const [openCreateGroup, onOpenCreateGroup, onCloseCreateGroup] = useDisclosureV2(false)
  const [openConnectEmail, onOpenConnectEmail, onCloseConnectEmail] = useDisclosureV2(false)
  const onConnectGoogle = async () => {
    try {
      const resp = await DefaultService.getGoogleOauth('inbox')
      window.location.href = resp.data.redirect_url
    } catch {
      toast.createToast({ message: 'Something went wrong', error: true })
    }
  }
  const onConnectMicrosoft = async () => {
    try {
      const resp = await DefaultService.getMicrosoftOauthInbox()
      window.location.href = resp.data.redirect_url
    } catch {
      toast.createToast({ message: 'Something went wrong', error: true })
    }
  }
  const { showFeatureFlag } = useFlags()

  return (
    <>
      <GroupFormDialog open={openCreateGroup} onClose={onCloseCreateGroup} mode="create" />
      <ConnectEmailDialog open={openConnectEmail} onClose={onCloseConnectEmail} />
      <DropdownMenu>
        <DropdownMenu.Button>
          <Plus className="h-4 w-4" />
        </DropdownMenu.Button>
        <DropdownMenu.Items className="right-0">
          <DropdownMenu.Item onClick={onOpenCreateGroup}>New sender group</DropdownMenu.Item>
          <DropdownMenu.Item onClick={onConnectMicrosoft}>Connect microsoft</DropdownMenu.Item>
          {showFeatureFlag && (
            <>
              {/* google still verifies our app and without setup on our side the google oauth does not work and for email. */}
              {/* Connect email: this is done in onboarding flow, but this flow (on senders page) is left if our team needs to add a single email easily */}
              <div className="my-0.5 border-t border-purple-700" />
              <DropdownMenu.Item onClick={onConnectGoogle}>Connect google</DropdownMenu.Item>
              <DropdownMenu.Item onClick={onOpenConnectEmail}>Connect email</DropdownMenu.Item>
            </>
          )}
        </DropdownMenu.Items>
      </DropdownMenu>
    </>
  )
}
